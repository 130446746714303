html {
  scroll-behavior: smooth;
  background-color: #0f213e;
}

body {
  margin: 0;
  padding: 0;
}

#app-bg {
  background-color: #0f213e;
  position: fixed;
  width: 100vw;
  height: 100vh;
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

a,
a:visited {
  color: #0063bb;
  text-decoration: none;
}

h6 {
  font-size: 20px !important;
}

h5 {
  font-size: 22px !important;
}
